import React from 'react';
import styles from './app.module.css';

class App extends React.Component {
    constructor(props) {
        super(props);
        if (window.localStorage.sadaqah === undefined) {
            window.localStorage.sadaqah = "0.00";
        }
        this.state = {
            sadaqah: parseFloat(window.localStorage.sadaqah) ?? 0.0,
            settings: false,
            newValue: null
        }
    }
    randomIntFromInterval(min, max) { // min and max included 
        return Math.floor(Math.random() * (max - min + 1) + min)
    }
    pickCoin() {
        let coinSet = [1, 5, 10, 25];
        let coin = this.randomIntFromInterval(1, 4);
        // divide by 100 to get dollar value
        return coinSet[coin - 1] / 100;
    }

    componentDidMount() {
        if (this.blurDiv) {
            setTimeout(() => {
                this.blurDiv.style.backdropFilter = 'blur(0)';
            }, 400);
        }
    }

    render() {
        return (
            <div className={styles.wrapper}>
                <div className={styles.vertical}>
                    <div className={styles.header}>
                        <span>Sadaqah</span>
                        <div className={styles.divider}></div>
                    </div>
                    <div className={styles.moneyContainer}
                        onClick={() => {
                            if (this.blurDiv) {
                                this.blurDiv.style.backdropFilter = 'blur(15px)';
                                this.setState({
                                    settings: true
                                });
                            }
                        }}>
                        <h1 className={styles.moneyAlt}>${this.state.sadaqah.toFixed(2)}</h1>
                        <h1 className={styles.moneyAmount}>${this.state.sadaqah.toFixed(2)}</h1>
                    </div>
                    <div className={styles.addbtn}>
                        <button
                            onClick={() => {
                                //window.localStorage.sadaqah += this.randomIntFromInterval(0.01, 0.25);
                                this.setState({
                                    sadaqah: (this.state.sadaqah + this.pickCoin())
                                }, () => {
                                    window.localStorage.sadaqah = this.state.sadaqah.toString();
                                });
                            }}
                        >GIVE</button>
                    </div>

                    <span className={styles.dua}>رَبِّ إِنِّى لِمَآ أَنزَلْتَ إِلَىَّ مِنْ خَيْرٍۢ فَقِيرٌۭ</span>
                </div>
                <div className={styles.blur} ref={(vert) => {
                    this.blurDiv = vert;
                }}></div>
                {this.state.settings ? (
                    <div className={styles.settings} onClick={() => {
                        if (this.blurDiv) {
                            this.blurDiv.style.backdropFilter = 'blur(0)';
                            this.setState({
                                settings: false,
                                sadaqah: this.state.newValue !== null ? parseFloat(this.state.newValue) : this.state.sadaqah,
                                newValue: null
                            }, () => {
                                window.localStorage.sadaqah = this.state.sadaqah === 0 ? "0.00" : this.state.sadaqah.toString();
                            });
                        }
                    }} ref={(d) => {
                        if (d) {
                            d.style.opacity = "1";
                        }
                    }}>
                        <span>Enter Custom Amount:</span>
                        <input type="text" value={this.state.newValue ?? this.state.sadaqah.toFixed(2)} className={styles.overrideInput} onClick={(e) => {
                            e.stopPropagation();
                        }} onChange={(e) => {
                            this.setState({
                                newValue: e.target.value,
                            });
                        }} />
                        <div className={styles.divider2}></div>
                        <button className={styles.clearButton}
                            onClick={(e) => {
                                e.stopPropagation();
                                if (this.blurDiv) {
                                    this.blurDiv.style.backdropFilter = 'blur(0)';
                                    this.setState({
                                        sadaqah: 0,
                                        settings: false
                                    });
                                    window.localStorage.sadaqah = "0.00";
                                }
                            }}>Clear</button>
                    </div>
                ) : null}
            </div>
        );
/*
        return (
            <div className={styles.wrapper}>
                <div className={styles.vertical}>
                    <div className={styles.header}>
                        Sadaqah
                    </div>
                    <h1>${this.state.sadaqah.toFixed(2)}</h1>
                    <div className={styles.addbtn}>
                        <button
                            onClick={() => {
                                //window.localStorage.sadaqah += this.randomIntFromInterval(0.01, 0.25);
                                this.setState({
                                    sadaqah: (this.state.sadaqah + (this.randomIntFromInterval(1,25)/100))
                                }, () => {
                                    window.localStorage.sadaqah = this.state.sadaqah.toFixed(2);
                                });
                            }}
                        >GIVE</button>
                    </div>
                    <button className={styles.clear}
                        onClick={() => {
                            this.setState({
                                sadaqah: 0
                            });
                            window.localStorage.sadaqah = '0.00';
                        }}>Clear</button>
                    <div />
                </div>
            </div>
        );*/
    }
}

export default App;
